@import "./_base.scss";

/*
    el-tabs样式修改
*/
.el-tabs{
    .el-tabs__nav-wrap{
        padding:0 20px;
    }
    .el-tabs__item{
        color:opacify($color-text-title,.65);
        &.is-active{
            color:#148EFF;
        }
    }
}

/*
    el-form样式修改
*/
.el-form{
    overflow:hidden;
    &.qilin-search-form{
        .el-input{
            width:150px;
        }
        .el-date-editor{
            width:280px;
            >.el-range-input{
                width:100px;
            }
            >.el-range-separator{
                width:8%;
            }
            &.date{
                width:150px;
            }
        }
    }
    .el-select{
        width:100%;
    }
    .el-date-editor{
        width:100%;
    }
    .el-form-item.search-btn{
        float:right;
    }
    .el-form-item__label{
        color:$color-text-title;
        span.ellipsis{
            display: inline-block;
            width:100%;
            overflow:hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
        }
    }
    .el-upload--picture-card{
        line-height:90px;
    }
    .el-upload--picture-card,.el-upload-list__item{
        @include setWH(80px,80px);
        >.el-upload-list__item-actions{
            line-height:80px;
        }
    }
    .upload-tip{
        font-size:$size-text-small;
        color:$color-text-invalid;
    }
    &.upload-form{
        .el-form-item__label{
            color:$color-text-title;
            font-weight:500;
        }
    }
}

/*
    el-table样式修改
*/
// .el-table{
//     th.el-table__cell{
//         background-color:$color-background-title;
//         color:$color-text-title;
//         text-align:center;
//     }
// }

/*
    el-pagination样式修改
*/
.el-pagination{
    text-align:right;
    margin-top:16px;
}

/*
    el-dialog样式修改
*/
.el-dialog{
    .el-dialog__header{
        text-align:left;
        >.el-dialog__title{
            color: $color-text-title;
            font-size:$size-text-medium;
        }
    }
    .el-dialog__footer{
        text-align:right;
    }
}

@import "assets/scss/_base.scss";
@import "assets/scss/element.scss";


html{
    font-size:87.5%; //浏览器默认大小为16px，本项目设置根元素默认字体大小为14px
}
html,body,#app{
    @include setWH;
    color:$color-text-common;
    font-family:$text-china-default,$text-english-default;
    background-color:$color-background-common;
    // overflow: hidden;
}
*{
    @include general;
    font-family: Source Han Sans CN-Regular;
}

// 定义基础颜色变量
$color-text-title:#17233d; //默认标题字体颜色
$color-text-common:#657180; //默认正文内容字体颜色
$color-text-invalid:#c3cbd6; //失效字体颜色
$color-theme-common:#032647; //主题颜色
$color-background-common:#F6F7FA; //全局背景颜色
$color-text:#fff; //全局字体颜色
$color-background-title:#FAFAFA; //标题背景颜色
$color-border:#efefef; //表格边框颜色

// 定义基础文字大小变量--根文件中设置html定义字体大小为16px * 87.5% = 14px，下面皆用rem换算
$size-text-mini:10rem / 14; //超小--10/14
$size-text-small:12rem / 14; //小型--12/14
$size-text-default:14rem / 14; //默认大小--14/14
$size-text-medium:16rem / 14; //中等大小--16/14
$size-text-large:18rem / 14; //大型--18/14
$size-text-larger:24rem / 14; //超大型--24/14
$size-text-max:40rem / 14; //最大型--40/14

// 定义基础文字字体变量
$text-china-default:"Hiragino Sans GB";
$text-english-default:"Helvetica";


// 设置水平+垂直样式
@mixin flexCenter($justify:center,$align:center) {
    display:flex;
    flex-flow:row nowrap;
    justify-content:$justify;
    align-items:$align;
}

// 设置通配符样式
@mixin general {
    padding:0;
    margin:0;
    box-sizing:border-box;
}

// 设置宽高样式
@mixin setWH($width:100%,$height:100%) {
    width:$width;
    height:$height;
}

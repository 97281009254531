@import "../assets/scss/_base.scss";
@import "../assets/scss/element.scss";


.retrievePassword{
  height: 100%;
  width: 100%;
}
.mainbody {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  background-color: #fff;
  min-width: 700px;
  background: url('../assets/images/forgetpwd.png') no-repeat;
  background-size: cover;
}
.mainbody>.box{
  width: 1000px;
  height: 460px;
  display: flex;
  margin: auto;
  margin-top: 10%;
  justify-content: center;
  background-color: #F7F9FE;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
  border-radius: 4px 4px 4px 4px;
}
.mainbody>.box>.form>p{
  text-align: center;
  padding: 25px 0 17px 0;
  font-size: 24px;
  font-family: Source Han Sans SC-Medium,Source Han Sans SC;
  line-height: 28px;
  font-weight: 500;
  color: #148EFF;
  border-bottom: 1px solid #D9D9D9;
  margin: 0 33px;
}
::v-deep .el-form .el-form-item__label{
  width: 100px;
}
::v-deep .el-row{
  padding-top: 64px;
  min-width: 950px;
}
.send{
  margin-left: 10px;
  border-color: #148EFF;
  color: #148EFF;

}

@import "../../assets/scss/_base.scss";
@import "../../assets/scss/element.scss";


::v-deep .el-dialog {
  transform: translateY(-50%);
}

.mainpage {
  height: 100%;
}

.mainbody {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
}

.showhide {
  width: 20px;
  height: 100px;
  line-height: 100px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: -17px;
}

.mainbody>.navmenu {
  width: 280px;
  min-width: 250px;
  height: 100%;
  /* background-color: yellow; */
  border-right: 1px solid #E9E9E9;
}

.mainbody>.navmenu>.ictlogo {
  width: 78%;
  height: 10%;
  margin: 0 auto;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

.mainbody>.navmenu>.ictlogo>.Ictlogoimg {
  width: 32px;
  height: 32px;
  float: left;
  margin-top: 10%;
}

.mainbody>.navmenu>.ictlogo>.Ictlogoimg>img {
  width: 100%;
  height: 100%;
}

.mainbody>.navmenu>.ictlogo>.Ictword {
  height: 32px;
  line-height: 32px;
  float: left;
  margin-left: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-top: 10%;
}

.mainbody>.navmenu>.eluinav {
  width: 90%;
  height: 88%;
  margin: 1% auto;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 0px;
}

.mainbody>.maintable {
  flex: 1;
  height: 100%;
  padding: 0 20px;
  overflow: hidden;
  min-width: 800px;
}

.mainbody>.maintable>.topmenu {
  width: 100%;
  height: 67px;
}

.mainbody>.maintable>.topmenu>.leftpart {
  width: 20%;
  height: 67px;
  float: left;
  display: flex;
  align-items: center;
}

.mainbody>.maintable>.topmenu>.leftpart>div {
  font-size: 16px;
  font-weight: 700;
  font-family: Source Han Sans CN-Medium;
  padding: 0 5px;
}

.mainbody>.maintable>.topmenu>.leftpart>div:nth-child(1) {
  color: #262626;
}

.mainbody>.maintable>.topmenu>.leftpart>div:nth-child(2) {
  color: #595959;
}

.mainbody>.maintable>.topmenu>.rightpart {
  width: 80%;
  height: 67px;
  float: right;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt {
  width: 256px;
  height: 30px;
  margin-top: 19px;
  float: right;
  border: 1px solid #d9d9d9;
  display: flex;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>input {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 12px;
  color: #000000 25%;
  text-indent: 16px;
  /* float: left; */
}

input::-webkit-input-placeholder {
  color: rgb(194, 189, 189);
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>i {
  flex: 1;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 6px;
  color: #d9d9d9;
}

.mainbody>.maintable>.topmenu>.rightpart>.ipt>span {
  line-height: 26px;
  font-size: 18px;
  color: #d9d9d9;
}

.mainbody>.maintable>.topmenu>.rightpart>.newadd {
  width: 80px;
  height: 30px;
  margin: 19px 8px 0 24px;
  float: right;
  border-radius: 2px;
  background-color: #deefff;
  color: #47a6ff;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #47a6ff;
  cursor: pointer;
}

.mainbody>.maintable>.topmenu>.rightpart>.delete {
  width: 104px;
  height: 30px;
  margin: 19px 8px 0 16px;
  float: right;
  border-radius: 2px;
  background-color: #ffeeee;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #ff4d4f;
  cursor: pointer;
}

.mainbody>.maintable>.topmenu>.rightpart>div>i {
  margin-right: 3px;
}

.mainbody>.maintable>.topmenu>.rightpart>.importment {
  width: 80px;
  height: 30px;
  float: right;
  margin: 19px 16px 0 8px;
  border-radius: 2px;
  background-color: #e7f8f2;
  color: #56cea3;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #56cea3;
}

.mainbody>.maintable>.topmenu>.rightpart>.exportment {
  width: 80px;
  height: 30px;
  margin-top: 19px;
  float: right;
  border-radius: 2px;
  background-color: #fef3dc;
  color: #faad14;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #faad14;
}

.mainbody>.maintable>.eluitable {
  /* width: 100%; */
  height: 100%;
}

.surname {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  /* border: 1px solid #000; */
  float: left;
}

.fullname {
  width: 55px;
  height: 28px;
  line-height: 28px;
  text-align: left;
  float: left;
  margin-left: 8px;
}
::v-deep .el-dialog .el-dialog__body{
  padding: 5px 20px;
}
.informationtop {
  width: 100%;
  height: 114px;
  border-bottom: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
}

.photo {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  margin: 20px 20px 20px 40px;
  background-color: #4BB859;
  float: left;
  line-height: 64px;
  text-align: center;
  color: #fff;
  font-size: 30px;
}

.introduce {
  width: 150px;
  height: 22px;
  float: left;
}

.introduce>.name {
  height: 100%;
  margin-top: 27px;
  margin-bottom: 10px;
  line-height: 22px;
  font-size: 20px;
  text-align: left;
  font-weight: 500;
}

.introduce>.infodepartment {
  height: 100%;
  line-height: 22px;
  font-size: 16px;
  text-align: left;
}

.introduce>.infodepartment>.pic {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 5px;
}

.introduce>.infodepartment>.pic>img {
  width: 100%;
  height: 100%;
}

.informationbottom {
  width: 100%;
  height: 170px;
}

.informationbottom>p {
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: #000;
  text-indent: 20px;
}
.informationbottom>div{
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  text-indent: 20px;
}
.informationbottom>div>.data{
  width: 90%;
  margin: 15px auto;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  text-indent: 20px;
}
.detailbut {
  color: #000000;
}

.informationbottom>p:nth-child(1) {
  margin-top: 30px;
}

.el-menu-item.is-active {
  background-color: #e8f4ff !important;
  color: #148eff;
  font-weight: 600;
  font-size: 14px;
}

.ckxqdiv {
  height: 200px;
  border-top: 1px solid #E9E9E9;
  padding: 0px 10px 10px 10px;

}

.ckxqdiv>div {
  width: 50%;
  height: 50px;
  float: left;
  text-align: left;
  line-height: 50px;
  color: #000000 60%;
}

.ckxqdiv>div>span {
  font-size: 14px;
}

.el-menu-item:hover {
  background-color: #e8f4ff !important;
}

// ::v-deep .el-submenu.is-active > .el-submenu__title {
//   color: #409EFF !important
// }
// .el-menu-item:hover[data-v-f3d43bd6] {
//     background-color: #e8f4ff !important;
//     color: #148eff;
//     font-weight: 600;
//     font-size: 14px;
// }
::v-deep .el-submenu__title.is-active {
  background-color: #e8f4ff !important;
  color: #148eff !important;
  font-weight: 600;
  font-size: 14px;
}

::v-deep .el-table th.el-table__cell>.cell {
  font-weight: 500 !important;
}

::v-deep .cell {
  /* padding-left: 80px !important */
}

::v-deep .el-table.normal {
  >.el-table__header-wrapper {
    th:first-of-type>div {
      padding-left: 80px;
    }
  }

  >.el-table__body-wrapper {
    td:first-of-type>div {
      padding-left: 80px;
    }
  }
}

::v-deep .el-table tbody tr:hover>td {
  background-color: #e8f4ff !important;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::v-deep .el-table__body .el-table__row:hover {
  cursor: pointer; //鼠标变小手
}

::v-deep .el-upload {
  width: 100% !important;
}

::v-deep .el-upload-dragger {
  margin: 0 auto;
}

::v-deep .el-pagination {
  font-weight: 500;
}
